.menu-item {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.menu-item p {
  margin: 0;
}
.menu-item small {
  display: block;
  font-weight: normal;
  font-style: italic;
}
.menu-item .text {
  width: calc(100% - 25px - 40px);
  word-break: break-word;
}
.menu-item .price {
  margin-left: 5px;
}
.menu-item:first-child {
  margin-top: 0;
}

.allergens {
  font-size: 0.625rem;
  color: var(--white);
  font-weight: bold;
  margin-left: 6px;
  vertical-align: text-top;
  line-height: 1.4;
  font-style: normal;
}
.allergens > div {
  display: inline-block;
  margin-right: 4px;
}
.allergens span {
  display: block;
  width: 16px;
  height: 16px;
  padding-top: 1px;
  border-radius: 50%;
  background-color: var(--dark-gray);
  text-align: center;
}