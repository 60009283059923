.language-switcher {
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.language-switcher li {
  float: left;
}
.language-switcher li:last-child a {
  border-right: none;
}
.language-switcher a {
  font-weight: 300;
  padding: 0 6px;
  border-right: 1px solid;
  text-transform: uppercase;
  text-decoration: none;
}
.language-switcher a.active {
  font-weight: 700;
}

@media only screen and (min-width: 480px) {
  .language-switcher {
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1025px) {
  .language-switcher a {
    padding: 0 7px;
  }
}