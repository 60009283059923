.restaurant-header {
  position: static;
  height: auto;
  margin-bottom: 40px;
  padding-top: 9px;
  padding-bottom: 10px;
}

.restaurant-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.restaurant-header-content:after {
  display: none !important;
}

.widget-info-block {
  margin-top: 40px;
  margin-bottom: -30px;
  padding-top: 30px;
  text-align: center;
  background-color: var(--gray);
}
.widget-info-block img {
  max-width: 100%;
  margin-top: -40px;
}
.widget-info-block.with-img {
  margin-top: 60px;
  padding-top: 0;
}

.logo {
  display: block;
  width: 104px;
  height: auto;
}

.restaurant-name {
  font-size: 1.5rem;
  font-weight: normal;
}

.copy-input {
  position: relative;
  margin-bottom: 40px;
}
.copy-input button {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 14px;
  color: var(--accent-color);
  font-weight: 700;
}
.copy-input input,
.copy-input textarea {
  padding-right: 95px;
}
.copy-input svg {
  top: 12px;
  left: 6px;
  width: 20px;
  color: var(--accent-color);
}

.widget-color {
  display: flex;
  align-items: center;
}
.widget-color h3 {
  margin-right: 30px;
}

.label {
  margin-right: 10px;
  font-size: 0.875rem;
}

.color-form-group {
  display: flex;
  align-items: center;
}
.color-form-group input {
  border: none;
  background-color: transparent;
  height: 30px;
  width: 60px;
  margin-left: -4px;
}

@media only screen and (min-width: 1025px) {
  .widget-info-block img {
    margin-top: -100px;
  }
  .widget-info-block.with-img {
    margin-top: 160px;
  }
}