.page-footer {
  margin-top: 30px;
  background-color: var(--white);
  background-color: var(--accent-color);
}
.page-footer ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}
.page-footer li {
  display: block;
}
.page-footer a {
  display: block;
  padding: 5px 0;
  text-transform: uppercase;
  color: var(--white);
}