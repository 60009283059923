.powered-by {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -20px -20px 10px;
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: var(--white);
  font-size: 0.75rem;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.booking-info-block {
  padding: 20px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  background-color: var(--white);
  border-radius: 16px;
  margin-bottom: 40px;
}

.bookings-info-section {
  margin-top: 30px;
}

.booking-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.guests-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.guests-wrapper .icon {
  width: auto;
  height: 40px;
  fill: var(--accent-color);
}

.toggle-seating {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  width: 200px;
  min-height: 32px;
  background-color: var(--gray);
  font-weight: 500;
  color: var(--lighter-text-color);
  border-radius: 20px;
}
.toggle-seating span {
  position: relative;
  z-index: 2;
  width: 50%;
  padding: 8px 5px;
  transition: color 0.4s;
  line-height: 1;
}
.toggle-seating span:first-child {
  color: var(--white);
}
.toggle-seating::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  background-color: var(--accent-color);
  border-radius: 20px;
  transition: left 0.4s, right 0.4s;
}
.toggle-seating[disabled] {
  cursor: not-allowed;
}
.toggle-seating[disabled]::before {
  background-color: var(--dark-gray);
}
.toggle-seating.active span:first-child {
  color: inherit;
}
.toggle-seating.active span:last-child {
  color: var(--white);
}
.toggle-seating.active::before {
  right: 0;
  left: 50%;
}

.please-select {
  padding: 20px;
  background-color: var(--gray);
  font-size: 1.25rem;
}

.hours-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 5px;
  margin-bottom: 20px;
}
.hours-wrapper button {
  position: relative;
  width: 100%;
  height: 54px;
  padding: 10px 0;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  border: 2px solid transparent;
  background-color: var(--gray);
  font-weight: 500;
}
.hours-wrapper button[disabled] {
  background-color: var(--gray);
  color: var(--lighter-text-color);
  cursor: default;
}
.hours-wrapper .hour {
  position: absolute;
  left: 4px;
  bottom: 4px;
}
.hours-wrapper .selected-hour {
  border-color: var(--accent-color);
  font-weight: 700;
}

.pick-hour.loading {
  position: relative;
  min-height: 100px;
}
.pick-hour.loading::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../../assets/images/loader-grey.gif) no-repeat center var(--gray);
  background-size: auto 86px;
}

.two-columns-form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
.two-columns-form-group > div {
  width: calc(50% - 10px);
}

.booking-info-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 1rem;
}
.booking-info-item .icon {
  flex-shrink: 0;
  width: 40px;
  margin-right: 5px;
  fill: currentColor;
  color: var(--accent-color);
}

@media only screen and (min-width: 569px) {
  .booking-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .date-picker,
.guests-wrapper {
    margin-bottom: 0;
  }
}