.tooltip-wrapper {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  position: relative;
}
.tooltip-wrapper::after {
  content: "";
  clear: both;
  display: table;
}

.tooltip-content {
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 50%;
  margin-top: 12px;
  min-width: 150px;
  transform: translateX(-50%);
  padding: 10px;
  background-color: var(--gray);
  color: var(--gray);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
}
.tooltip-content p {
  margin: 0;
  color: var(--primary-color);
}
.tooltip-content::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  left: 50%;
  bottom: 100%;
  border-width: 0 8px 10px 8px;
  border-color: transparent transparent currentColor transparent;
  transform: translateX(-50%);
}