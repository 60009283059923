.pin-icon {
  width: 36px;
  margin-left: -6px;
  margin-right: 0;
}

.clear-query {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;
}
.clear-query .icon {
  width: 30px;
  fill: var(--primary-color);
  background-color: transparent;
}

.autocomplete-input .error {
  position: absolute;
  margin-top: -5px;
}
.autocomplete-input .icon-search {
  fill: var(--white);
}

@media only screen and (min-width: 1025px) {
  .autocomplete-input {
    width: 100%;
    margin-bottom: 0;
    padding-right: 30px;
    border-right: 1px solid var(--border-color);
  }
  .autocomplete-input input {
    height: 36px;
  }
  .autocomplete-input .error {
    margin-top: 0;
  }
}