.restaurant-menu {
  margin-bottom: 25px;
  font-weight: 500;
}
.restaurant-menu select {
  height: 38px;
  padding-bottom: 15px;
  font-size: 1.125rem;
}
.restaurant-menu .form-group {
  margin-bottom: 24px;
}