.btn {
  display: block;
  width: 100%;
  margin-top: 20px;
  padding: 10px 40px;
  border-style: solid;
  border-width: 2px;
  color: var(--white);
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  outline-color: transparent;
  text-transform: uppercase;
  text-decoration: none;
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  transition: box-shadow 0.2s;
}
.btn[disabled] {
  background: var(--light-gray);
  border-color: var(--light-gray);
  color: var(--white);
  cursor: not-allowed;
}
.btn:hover, .btn:focus {
  box-shadow: 0 0 6px var(--accent-color);
  background-color: var(--accent-color);
}

.btn-outline {
  background: transparent;
  color: var(--accent-color);
}
.btn-outline:hover, .btn-outline:focus {
  background-color: transparent;
}

.btn-discount {
  color: #7CC52C;
  border-color: #7CC52C;
}
.btn-discount:hover, .btn-discount:focus {
  box-shadow: 0 0 6px #7CC52C;
}

.btn-tasting {
  color: #A61C3C;
  border-color: #A61C3C;
}
.btn-tasting:hover, .btn-tasting:focus {
  box-shadow: 0 0 6px #A61C3C;
}

.btn-event {
  color: #00C5EE;
  border-color: #00C5EE;
}
.btn-event:hover, .btn-event:focus {
  box-shadow: 0 0 6px #00C5EE;
}

.loading-btn {
  position: relative;
  padding-left: 50px;
}