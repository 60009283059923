.counter {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 700;
}
.counter button {
  width: 40px;
  height: 40px;
  background-color: var(--gray);
  border: 0;
  cursor: pointer;
  outline: none;
  font-size: 1.0625rem;
  font-weight: 700;
}
.counter button[disabled] {
  cursor: default;
}
.counter p {
  margin: 0;
}

.count {
  width: 30px;
}