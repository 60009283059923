.form-group {
  margin-bottom: 10px;
}
.form-group input,
.form-group select {
  width: 100%;
  height: 46px;
  padding: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--primary-color);
  background-color: transparent;
  outline: none;
}
.form-group input[disabled],
.form-group select[disabled] {
  background-color: var(--gray);
}
.form-group select {
  padding-right: 28px;
}
.form-group.error input,
.form-group.error select,
.form-group.error textarea {
  border-color: var(--error-color);
}
.form-group.error p {
  margin: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background: url(../../react-web-ui/assets/images/icons/ArrowDown.svg) no-repeat center right;
}