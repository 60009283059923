@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
:root {
  --white: #fff;
  --black: #000;
  --primary-color: #2e2e2e;
  --primary-color-hover: #3d3d3d;
  --accent-color: #FFAE42;
  --accent-color-hover: #ffebcd;
  --awards-color: #2f4858;
  --gray: #f1f3f4;
  --dark-gray: #686867;
  --medium-gray: #D6D6D6;
  --light-gray: #E2E2EA;
  --header-border: #b1b1b1;
  --error-color: #d64e2f;
  --success-color: #7CC52C;
  --checkbox-bg: #f5f0e4;
  --border-color: #e6e6e6;
  --footer-color: #726f68;
  --lighter-text-color: #adadad;
}

.discount-page {
  --accent-color-hover: #bee296;
}

.tasting-page {
  --accent-color-hover: #D28D9D;
}

.event-page {
  --accent-color-hover: #95edff;
}

.page-heading-default {
  background-image: url("../images/default.png");
}

.page-heading-discount {
  background-image: url("../images/discount.png");
}

.page-heading-tasting {
  background-image: url("../images/tasting.png");
}

.page-heading-event {
  background-image: url("../images/event.png");
}

.page-heading-menu {
  background-image: url("../images/menu.png");
}

.page-heading-about-us {
  background-image: url("../images/about.png");
}

.text-gray {
  color: var(--lighter-text-color);
}

.page-heading-default,
.page-heading-discount,
.page-heading-tasting,
.page-heading-event,
.page-heading-menu,
.page-heading-about-us {
  background-position: top right;
  background-repeat: no-repeat;
}

.btn-white.btn-accent:not(.btn-outline) {
  color: var(--accent-color);
}

.default-page,
.time-picker-default {
  --accent-color: #FFAE42;
}

.text-default {
  color: #FFAE42;
}

.bg-default {
  background-color: #FFAE42;
}

.btn-default {
  background: #FFAE42;
  border-color: #FFAE42;
  transition: box-shadow 0.2s;
}
.btn-default.btn-outline {
  background: transparent;
  color: #FFAE42;
}
.btn-default:hover, .btn-default:focus {
  box-shadow: 0 0 6px #FFAE42;
}

.main-nav .border-link-default::after {
  background-color: #FFAE42;
}

.dark-page,
.time-picker-dark {
  --accent-color: #2e2e2e;
}

.text-dark {
  color: #2e2e2e;
}

.bg-dark {
  background-color: #2e2e2e;
}

.btn-dark {
  background: #2e2e2e;
  border-color: #2e2e2e;
  transition: box-shadow 0.2s;
}
.btn-dark.btn-outline {
  background: transparent;
  color: #2e2e2e;
}
.btn-dark:hover, .btn-dark:focus {
  box-shadow: 0 0 6px #2e2e2e;
}

.main-nav .border-link-dark::after {
  background-color: #2e2e2e;
}

.accent-page,
.time-picker-accent {
  --accent-color: var(--accent-color);
}

.text-accent {
  color: var(--accent-color);
}

.bg-accent {
  background-color: var(--accent-color);
}

.btn-accent {
  background: var(--accent-color);
  border-color: var(--accent-color);
  transition: box-shadow 0.2s;
}
.btn-accent.btn-outline {
  background: transparent;
  color: var(--accent-color);
}
.btn-accent:hover, .btn-accent:focus {
  box-shadow: 0 0 6px var(--accent-color);
}

.main-nav .border-link-accent::after {
  background-color: var(--accent-color);
}

.lunch-page,
.time-picker-lunch {
  --accent-color: #00DB96;
}

.text-lunch {
  color: #00DB96;
}

.bg-lunch {
  background-color: #00DB96;
}

.btn-lunch {
  background: #00DB96;
  border-color: #00DB96;
  transition: box-shadow 0.2s;
}
.btn-lunch.btn-outline {
  background: transparent;
  color: #00DB96;
}
.btn-lunch:hover, .btn-lunch:focus {
  box-shadow: 0 0 6px #00DB96;
}

.main-nav .border-link-lunch::after {
  background-color: #00DB96;
}

.tasting-page,
.time-picker-tasting {
  --accent-color: #A61C3C;
}

.text-tasting {
  color: #A61C3C;
}

.bg-tasting {
  background-color: #A61C3C;
}

.btn-tasting {
  background: #A61C3C;
  border-color: #A61C3C;
  transition: box-shadow 0.2s;
}
.btn-tasting.btn-outline {
  background: transparent;
  color: #A61C3C;
}
.btn-tasting:hover, .btn-tasting:focus {
  box-shadow: 0 0 6px #A61C3C;
}

.main-nav .border-link-tasting::after {
  background-color: #A61C3C;
}

.event-page,
.time-picker-event {
  --accent-color: #00C5EE;
}

.text-event {
  color: #00C5EE;
}

.bg-event {
  background-color: #00C5EE;
}

.btn-event {
  background: #00C5EE;
  border-color: #00C5EE;
  transition: box-shadow 0.2s;
}
.btn-event.btn-outline {
  background: transparent;
  color: #00C5EE;
}
.btn-event:hover, .btn-event:focus {
  box-shadow: 0 0 6px #00C5EE;
}

.main-nav .border-link-event::after {
  background-color: #00C5EE;
}

.discount-page,
.time-picker-discount {
  --accent-color: #7CC52C;
}

.text-discount {
  color: #7CC52C;
}

.bg-discount {
  background-color: #7CC52C;
}

.btn-discount {
  background: #7CC52C;
  border-color: #7CC52C;
  transition: box-shadow 0.2s;
}
.btn-discount.btn-outline {
  background: transparent;
  color: #7CC52C;
}
.btn-discount:hover, .btn-discount:focus {
  box-shadow: 0 0 6px #7CC52C;
}

.main-nav .border-link-discount::after {
  background-color: #7CC52C;
}

.giftcard-page,
.time-picker-giftcard {
  --accent-color: #ffb691;
}

.text-giftcard {
  color: #ffb691;
}

.bg-giftcard {
  background-color: #ffb691;
}

.btn-giftcard {
  background: #ffb691;
  border-color: #ffb691;
  transition: box-shadow 0.2s;
}
.btn-giftcard.btn-outline {
  background: transparent;
  color: #ffb691;
}
.btn-giftcard:hover, .btn-giftcard:focus {
  box-shadow: 0 0 6px #ffb691;
}

.main-nav .border-link-giftcard::after {
  background-color: #ffb691;
}

.ratings-page,
.time-picker-ratings {
  --accent-color: var(--awards-color);
}

.text-ratings {
  color: var(--awards-color);
}

.bg-ratings {
  background-color: var(--awards-color);
}

.btn-ratings {
  background: var(--awards-color);
  border-color: var(--awards-color);
  transition: box-shadow 0.2s;
}
.btn-ratings.btn-outline {
  background: transparent;
  color: var(--awards-color);
}
.btn-ratings:hover, .btn-ratings:focus {
  box-shadow: 0 0 6px var(--awards-color);
}

.main-nav .border-link-ratings::after {
  background-color: var(--awards-color);
}

.white-page,
.time-picker-white {
  --accent-color: var(--white);
}

.text-white {
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
}

.btn-white {
  background: var(--white);
  border-color: var(--white);
  transition: box-shadow 0.2s;
}
.btn-white.btn-outline {
  background: transparent;
  color: var(--white);
}
.btn-white:hover, .btn-white:focus {
  box-shadow: 0 0 6px var(--white);
}

.main-nav .border-link-white::after {
  background-color: var(--white);
}

.form-group {
  margin-bottom: 10px;
  background-color: var(--white);
}
.form-group label {
  display: block;
  margin-bottom: 5px;
}
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--primary-color);
  background-color: transparent;
  outline: none;
}
.form-group input[disabled],
.form-group select[disabled],
.form-group textarea[disabled] {
  background-color: var(--gray);
}
.form-group input[disabled] + svg,
.form-group select[disabled] + svg,
.form-group textarea[disabled] + svg {
  z-index: 1;
}
.form-group input,
.form-group select {
  height: 46px;
}
.form-group select {
  padding-right: 28px;
}
.form-group textarea {
  height: 110px;
  padding-top: 10px;
  resize: none;
}
.form-group svg {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 8px;
  width: 30px;
  height: auto;
}
.form-group .date-picker {
  padding-left: 35px;
  border-bottom: 1px solid var(--primary-color);
}
.form-group .date-picker > svg {
  left: -7px;
  top: 3px;
}
.form-group .select-item {
  color: var(--primary-color);
}
.form-group .select-item.selected {
  background-color: var(--accent-color);
  color: var(--white);
}
.form-group.error input,
.form-group.error select,
.form-group.error textarea {
  border-color: var(--error-color);
}
.form-group.error p {
  margin: 0;
}

.has-icon input,
.has-icon select,
.has-icon textarea {
  padding-left: 34px;
}

p.error,
p.success {
  font-size: 0.75rem;
}

p.error,
p.success,
h3.error,
h3.success {
  margin-top: 0;
}

p.error,
h3.error {
  color: var(--error-color) !important;
}

p.success,
h3.success {
  color: var(--success-color) !important;
}

.input-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
}

.checkbox,
.radio {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  font-size: 0.9375rem;
  cursor: pointer;
}
.checkbox input,
.radio input {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}
.checkbox input:checked + .box::after,
.radio input:checked + .box::after {
  content: "";
  position: absolute;
}
.checkbox .text,
.radio .text {
  display: block;
}
.checkbox .box,
.radio .box {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 12px;
  background-color: var(--gray);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background: url(../../assets/images/icons/ArrowDown.svg) no-repeat center right;
}

.rmsc.multi-select {
  --rmsc-selected: #FFAE42;
  --rmsc-main: #2e2e2e;
  --rmsc-hover: #f1f3f5;
  --rmsc-border: #2e2e2e;
  --rmsc-radius: 0;
}
.rmsc.multi-select .dropdown-container {
  border: none;
  border-bottom: 1px solid var(--primary-color);
}
.rmsc.multi-select .dropdown-container input[type=checkbox] {
  display: none;
}
.rmsc.multi-select .dropdown-container .dropdown-heading-dropdown-arrow {
  color: var(--primary-color);
}
.rmsc.multi-select .dropdown-container .dropdown-heading-dropdown-arrow svg {
  width: 30px;
  height: auto;
  margin-right: -5px;
}
.rmsc.multi-select .dropdown-container:focus-within {
  box-shadow: none;
}
.rmsc.multi-select .dropdown-heading {
  height: 46px;
  padding: 0;
}
.rmsc.multi-select .dropdown-content {
  z-index: 2;
}

.checkbox input:checked + .box::after {
  top: -3px;
  right: 0;
  width: 8px;
  height: 16px;
  border-bottom: 2px solid var(--accent-color);
  border-right: 2px solid var(--accent-color);
  transform: rotate(45deg);
}

.checkbox a {
  text-decoration: underline;
  color: var(--accent-color);
}

.options-inline {
  margin-bottom: 20px;
}
.options-inline .checkbox,
.options-inline .radio {
  display: inline-flex;
  margin-right: 20px;
}

.page-header {
  position: absolute;
  z-index: 6;
  left: 0;
  right: 0;
  top: 0;
  height: 84px;
  background-color: var(--white);
  border-bottom: 1px solid var(--header-border);
}
.page-header a {
  text-decoration: none;
}
.page-header ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.logo {
  float: left;
  height: 38px;
  width: 38px;
  margin-top: 11px;
  overflow: hidden;
}
.logo svg {
  color: var(--primary-color);
  height: 38px;
  width: 162px;
}

.website-links {
  background-color: var(--gray);
  font-size: 0.75rem;
}
.website-links a {
  display: block;
  margin-left: 5px;
  padding: 5px 10px;
}
.website-links a.selected, .website-links a:hover {
  background-color: var(--medium-gray);
}
.website-links .inner-wrapper {
  display: flex;
  justify-content: flex-end;
}

.header-right {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 18px;
}
.header-right .btn {
  min-width: 114px;
  height: 30px;
  padding: 3px 10px;
  font-size: 1rem;
  font-weight: normal;
  text-transform: none;
}

.auth-block .btn {
  display: inline-block;
}

.mobile-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 0;
  overflow: auto;
  font-size: 1.3125rem;
  font-weight: 600;
  background-color: var(--white);
  border-top: 1px solid var(--header-border);
  transition: height 0.6s;
}
.mobile-menu a,
.mobile-menu button {
  display: block;
  padding: 12px 14px;
}
.mobile-menu a.active,
.mobile-menu button.active {
  background-color: var(--gray);
}
.mobile-menu button {
  width: 100%;
  text-align: left;
}
.mobile-menu.open {
  height: calc(100vh - 50px);
}

.main-nav {
  padding-top: 20px;
}

.city-list {
  display: none;
  margin-bottom: 10px;
  font-size: 0.875rem;
  font-weight: normal;
}
.city-list a {
  padding: 2px 10px;
}

.mobile-menu-link {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  position: relative;
  width: 25px;
  height: 18px;
  margin-left: 2px;
  font-size: 0;
}
.mobile-menu-link span,
.mobile-menu-link span::before,
.mobile-menu-link span::after {
  position: absolute;
  left: 0;
  right: 0;
  height: 3px;
  background-color: var(--primary-color);
}
.mobile-menu-link span {
  top: 50%;
  margin-top: -3px;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 75ms;
}
.mobile-menu-link span::before, .mobile-menu-link span::after {
  content: "";
}
.mobile-menu-link span::before {
  top: -8px;
  transition: top 75ms ease 0.12s, opacity 75ms ease;
}
.mobile-menu-link span:after {
  bottom: -8px;
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.mobile-menu-link.open span {
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}
.mobile-menu-link.open span::before {
  top: 0;
  transition: top 75ms ease, opacity 75ms ease 0.12s;
  opacity: 0;
}
.mobile-menu-link.open span::after {
  bottom: 0;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg);
}

@media only screen and (min-width: 480px) {
  .logo {
    height: 42px;
    width: 42px;
  }
  .logo svg {
    height: 42px;
    width: 180px;
  }

  .auth-block .btn {
    margin-right: 12px;
  }

  .header-right .btn {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-menu-link {
    width: 30px;
    height: 30px;
    margin-left: 12px;
  }
  .mobile-menu-link span::before {
    top: -10px;
  }
  .mobile-menu-link span::after {
    bottom: -10px;
  }
}
@media only screen and (min-width: 1180px) {
  .page-header {
    height: 110px;
  }
  .page-header .language-switcher {
    margin-right: -5px;
  }
  .page-header.fixed, .has-fixed-header .page-header {
    height: 50px;
  }
  .page-header.fixed .logo, .has-fixed-header .page-header .logo {
    height: 40px;
    width: 40px;
    margin-top: 4px;
  }
  .page-header.fixed .logo svg, .has-fixed-header .page-header .logo svg {
    height: 40px;
    width: 172px;
  }
  .page-header.fixed .main-nav, .has-fixed-header .page-header .main-nav {
    position: static;
    padding-top: 0;
    font-size: 1rem;
  }
  .page-header.fixed .main-nav li, .has-fixed-header .page-header .main-nav li {
    margin: 0 10px;
  }
  .page-header.fixed .main-nav a,
.page-header.fixed .main-nav button, .has-fixed-header .page-header .main-nav a,
.has-fixed-header .page-header .main-nav button {
    padding-top: 14px;
  }
  .page-header.fixed .secondary-nav, .has-fixed-header .page-header .secondary-nav {
    display: none;
  }

  .website-links {
    font-size: 0.9375rem;
  }
  .website-links a {
    margin-left: 10px;
    padding: 10px 16px;
  }

  .logo {
    position: absolute;
    height: 48px;
    width: auto;
    margin-top: 32px;
  }
  .logo svg {
    height: 48px;
    width: 208px;
  }

  .header-right {
    min-height: 53px;
    margin-top: 0;
  }

  .auth-block {
    margin-left: 16px;
  }
  .auth-block .btn {
    margin-right: 25px;
  }

  .mobile-menu-link {
    display: none;
  }

  .mobile-menu {
    position: static;
    display: block;
    height: auto;
    padding: 0;
    font-size: 1.125rem;
    border-top: 0;
    background-color: transparent;
  }
  .mobile-menu.open {
    height: auto;
  }

  .main-nav li,
.secondary-nav li {
    float: left;
  }

  .secondary-nav {
    font-size: 1rem;
    font-weight: 300;
  }
  .secondary-nav a {
    padding: 17px 25px;
  }
  .secondary-nav a:hover,
.secondary-nav a.active,
.secondary-nav a:focus {
    background-color: var(--gray);
  }

  .main-nav {
    position: absolute;
    right: 6px;
    bottom: 5px;
  }
  .main-nav li {
    margin: 0 25px;
  }
  .main-nav a,
.main-nav button {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  .main-nav a::after,
.main-nav button::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 8px;
    width: 0;
    height: 3px;
    background-color: var(--accent-color);
    transition: width 0.4s;
  }
  .main-nav a.active,
.main-nav button.active {
    background-color: transparent;
  }
  .main-nav a:hover::after, .main-nav a.active::after,
.main-nav button:hover::after,
.main-nav button.active::after {
    width: 100%;
  }
}
.page-heading {
  margin-bottom: 25px;
  padding: 20px;
  border-radius: 16px;
  background-color: var(--gray);
  background-size: auto 130px;
  background-position: right -10px top -14px;
}
.page-heading h1 {
  margin-top: 0;
  margin-bottom: 18px;
  padding-right: 100px;
  font-size: 1rem;
}

@media only screen and (min-width: 1025px) {
  .page-heading {
    padding: 28px 24px;
    background-size: auto 160%;
    background-position: right -50px top -114px;
  }
  .page-heading h1 {
    font-size: 1.5rem;
  }
}
/**
* App-wide styles
*/
.widget-page {
  margin: 0;
  padding: 0;
}
.widget-page a {
  color: inherit;
}

.dineout-widget {
  max-width: 1200px;
  margin: 30px auto;
}
.dineout-widget a {
  color: inherit;
}

.widget-page .termsfeed-com---palette-light .cc-nb-okagree,
.widget-page .termsfeed-com---palette-light .cc-nb-reject {
  background-color: var(--accent-color) !important;
}

.dineout-widget,
.widget-landing {
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}
.dineout-widget *,
.dineout-widget *::after,
.dineout-widget *::before,
.widget-landing *,
.widget-landing *::after,
.widget-landing *::before {
  box-sizing: inherit;
}
.dineout-widget input,
.dineout-widget select,
.dineout-widget textarea,
.dineout-widget button,
.widget-landing input,
.widget-landing select,
.widget-landing textarea,
.widget-landing button {
  font-family: inherit;
  font-size: inherit;
}
.dineout-widget input,
.widget-landing input {
  border-radius: 0;
  -webkit-border-radius: 0;
}
.dineout-widget td,
.dineout-widget th,
.widget-landing td,
.widget-landing th {
  padding: 0;
  width: 38px;
  height: 38px;
}
.dineout-widget p,
.widget-landing p {
  margin: 0 0 10px;
  padding: 0;
}
.dineout-widget .white-checkbox .box,
.widget-landing .white-checkbox .box {
  background-color: var(--white);
}
.dineout-widget .CalendarDay__default,
.widget-landing .CalendarDay__default {
  background-color: var(--accent-color-hover);
  border: 4px solid var(--white);
}
.dineout-widget .CalendarDay__default:hover,
.widget-landing .CalendarDay__default:hover {
  border: 4px solid var(--white);
}
.dineout-widget .CalendarDay__selected,
.dineout-widget .CalendarDay__selected:active,
.dineout-widget .CalendarDay__selected:hover,
.widget-landing .CalendarDay__selected,
.widget-landing .CalendarDay__selected:active,
.widget-landing .CalendarDay__selected:hover {
  background-color: var(--accent-color);
  border: 4px solid var(--white);
}
.dineout-widget .CalendarDay__default:hover,
.widget-landing .CalendarDay__default:hover {
  background-color: var(--accent-color);
  color: var(--white);
}
.dineout-widget .CalendarDay__selected_span,
.dineout-widget .CalendarDay__hovered_span,
.widget-landing .CalendarDay__selected_span,
.widget-landing .CalendarDay__hovered_span {
  background-color: var(--accent-color);
  color: var(--white);
}
.dineout-widget .CalendarDay__blocked_calendar,
.dineout-widget .CalendarDay__blocked_calendar:active,
.dineout-widget .CalendarDay__blocked_calendar:hover,
.dineout-widget .CalendarDay__blocked_out_of_range,
.dineout-widget .CalendarDay__blocked_out_of_range:hover,
.widget-landing .CalendarDay__blocked_calendar,
.widget-landing .CalendarDay__blocked_calendar:active,
.widget-landing .CalendarDay__blocked_calendar:hover,
.widget-landing .CalendarDay__blocked_out_of_range,
.widget-landing .CalendarDay__blocked_out_of_range:hover {
  background-color: var(--gray);
  color: var(--dark-gray);
}
.dineout-widget .CalendarMonth_caption,
.widget-landing .CalendarMonth_caption {
  padding-top: 0;
  padding-bottom: 43px;
  color: var(--primary-color);
}
.dineout-widget .CalendarMonth_caption strong,
.widget-landing .CalendarMonth_caption strong {
  font-weight: 500;
}
.dineout-widget .DayPicker_weekHeader_li,
.widget-landing .DayPicker_weekHeader_li {
  margin: 0 0.5px;
}
.dineout-widget .DayPicker_weekHeader_li small,
.widget-landing .DayPicker_weekHeader_li small {
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-color);
}
.dineout-widget .DayPicker_weekHeader,
.widget-landing .DayPicker_weekHeader {
  top: 36px;
  margin-left: -10px;
}
.dineout-widget .CalendarMonthGrid__horizontal,
.widget-landing .CalendarMonthGrid__horizontal {
  left: 0;
}
.dineout-widget .CalendarDay,
.widget-landing .CalendarDay {
  font-size: 1rem;
  font-weight: 500;
}
.dineout-widget .DayPicker__horizontal,
.widget-landing .DayPicker__horizontal {
  margin: 0 auto;
}
.dineout-widget .CalendarMonth_caption > div > div,
.widget-landing .CalendarMonth_caption > div > div {
  margin: 0 5px;
}
.dineout-widget .CalendarMonth_caption select,
.widget-landing .CalendarMonth_caption select {
  height: 24px;
  border-bottom: 0;
  padding-right: 20px;
}
.dineout-widget .section,
.widget-landing .section {
  margin-bottom: 40px;
}
.dineout-widget .section-title,
.widget-landing .section-title {
  margin: 0 0 22px;
  font-size: 1.5rem;
  font-weight: 400;
}
.dineout-widget .section-sub-title,
.widget-landing .section-sub-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 400;
}
.dineout-widget p.error,
.dineout-widget p.success,
.widget-landing p.error,
.widget-landing p.success {
  margin: 0;
  font-size: 0.75rem;
}
.dineout-widget .error,
.widget-landing .error {
  color: var(--error-color);
}
.dineout-widget .success,
.widget-landing .success {
  color: var(--success-color);
}
.dineout-widget .star-ratings,
.widget-landing .star-ratings {
  display: inline-block;
  margin-right: 10px;
}
.dineout-widget .CircularProgressbar .CircularProgressbar-path,
.widget-landing .CircularProgressbar .CircularProgressbar-path {
  stroke: var(--awards-color);
  transition: stroke-dashoffset 1s ease 0s;
}
.dineout-widget .CircularProgressbar .CircularProgressbar-text,
.widget-landing .CircularProgressbar .CircularProgressbar-text {
  fill: var(--awards-color);
  font-size: 1.625rem;
  dominant-baseline: central;
}
.dineout-widget .lg-two-cols .col,
.widget-landing .lg-two-cols .col {
  margin-bottom: 40px;
}
.dineout-widget .slick-prev,
.dineout-widget .slick-next,
.widget-landing .slick-prev,
.widget-landing .slick-next {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 0;
}
.dineout-widget .gray-block,
.widget-landing .gray-block {
  flex-shrink: 0;
  width: 190px;
  margin-top: 30px;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--gray);
}
.dineout-widget .gray-block p,
.widget-landing .gray-block p {
  margin: 0;
}
.dineout-widget .gray-block .right,
.widget-landing .gray-block .right {
  display: block;
  text-align: right;
}
.dineout-widget .gray-block.full-width,
.widget-landing .gray-block.full-width {
  width: 100%;
  margin: 0;
}
@media only screen and (min-width: 480px) {
  .dineout-widget .gray-block,
.widget-landing .gray-block {
    margin-top: 0;
    margin-left: 30px;
  }
}
@media only screen and (min-width: 1025px) {
  .dineout-widget .lg-two-cols,
.dineout-widget .lg-three-cols,
.widget-landing .lg-two-cols,
.widget-landing .lg-three-cols {
    display: flex;
    justify-content: space-between;
  }
  .dineout-widget .lg-two-cols,
.widget-landing .lg-two-cols {
    flex-wrap: wrap;
  }
  .dineout-widget .lg-two-cols .col,
.widget-landing .lg-two-cols .col {
    width: 48.3%;
  }
}

.widget-landing .icon {
  fill: var(--accent-color);
}
.widget-landing .info-item {
  display: flex;
  align-items: flex-start;
  width: 49%;
  margin-bottom: 22px;
}
.widget-landing .info-item h3 {
  margin: 0;
  font-weight: 500;
}
.widget-landing .info-item p {
  margin: 4px 0 0;
}
.widget-landing .info-item .icon {
  flex-shrink: 0;
  width: 25px;
  height: auto;
  margin-top: -4px;
}
.widget-landing .info-item .text a {
  display: block;
}
.widget-landing .inner-wrapper {
  margin: 0 auto;
  padding: 0 14px;
  max-width: 1348px;
  width: 100%;
}
.widget-landing .inner-wrapper::after {
  content: "";
  clear: both;
  display: table;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  border: none;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  z-index: 2;
  left: 34px;
  top: 100%;
  width: 100%;
  margin-top: 5px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  background-color: var(--white);
  font-size: 1rem;
  border-radius: 5px;
}

.react-autosuggest__suggestions-list {
  max-height: 300px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: auto;
}
.react-autosuggest__suggestions-list::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}
.react-autosuggest__suggestions-list::-webkit-scrollbar-track {
  background-color: var(--gray);
  border-radius: 20px;
}
.react-autosuggest__suggestions-list::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 20px;
}

.react-autosuggest__suggestions-list .react-autosuggest__suggestion svg {
  position: relative;
  z-index: 1;
  top: 0;
  flex-shrink: 0;
  width: 20px;
  height: auto;
  margin-right: 10px;
  background-color: transparent;
  color: var(--primary-color);
  transform: none;
}

.react-autosuggest__suggestion {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid var(--border-color);
}

.react-autosuggest__suggestion--highlighted {
  background-color: var(--gray);
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.custom-phone-input .react-tel-input .form-control {
  width: 100%;
  height: 46px;
  border: 0;
  border-radius: 0;
  padding-left: 56px;
  border-bottom: 1px solid var(--primary-color);
}
.custom-phone-input .react-tel-input .flag-dropdown {
  background-color: var(--white);
}
.custom-phone-input .react-tel-input .flag-dropdown,
.custom-phone-input .react-tel-input .country-list .search {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--primary-color);
}
.custom-phone-input .react-tel-input .selected-flag {
  width: 44px;
}
.custom-phone-input .react-tel-input .selected-flag .arrow {
  left: 24px;
}
.custom-phone-input .react-tel-input .country-list .search-box {
  width: calc(100% - 32px);
  border: none;
}

.form-group.error .custom-phone-input .react-tel-input .form-control,
.form-group.error .custom-phone-input .react-tel-input .flag-dropdown {
  border-color: var(--error-color);
}

:global .widget-page {
  margin: 0;
  padding: 0;
}
:global .widget-page a {
  color: inherit;
}
:global .dineout-widget {
  max-width: 1200px;
  margin: 30px auto;
}
:global .dineout-widget a {
  color: inherit;
}
:global .widget-page .termsfeed-com---palette-light .cc-nb-okagree,
:global .widget-page .termsfeed-com---palette-light .cc-nb-reject {
  background-color: var(--accent-color) !important;
}
:global .dineout-widget,
:global .widget-landing {
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}
:global .dineout-widget *,
:global .dineout-widget *::after,
:global .dineout-widget *::before,
:global .widget-landing *,
:global .widget-landing *::after,
:global .widget-landing *::before {
  box-sizing: inherit;
}
:global .dineout-widget input,
:global .dineout-widget select,
:global .dineout-widget textarea,
:global .dineout-widget button,
:global .widget-landing input,
:global .widget-landing select,
:global .widget-landing textarea,
:global .widget-landing button {
  font-family: inherit;
  font-size: inherit;
}
:global .dineout-widget input,
:global .widget-landing input {
  border-radius: 0;
  -webkit-border-radius: 0;
}
:global .dineout-widget td,
:global .dineout-widget th,
:global .widget-landing td,
:global .widget-landing th {
  padding: 0;
  width: 38px;
  height: 38px;
}
:global .dineout-widget p,
:global .widget-landing p {
  margin: 0 0 10px;
  padding: 0;
}
:global .dineout-widget .white-checkbox .box,
:global .widget-landing .white-checkbox .box {
  background-color: var(--white);
}
:global .dineout-widget .CalendarDay__default,
:global .widget-landing .CalendarDay__default {
  background-color: var(--accent-color-hover);
  border: 4px solid var(--white);
}
:global .dineout-widget .CalendarDay__default:hover,
:global .widget-landing .CalendarDay__default:hover {
  border: 4px solid var(--white);
}
:global .dineout-widget .CalendarDay__selected,
:global .dineout-widget .CalendarDay__selected:active,
:global .dineout-widget .CalendarDay__selected:hover,
:global .widget-landing .CalendarDay__selected,
:global .widget-landing .CalendarDay__selected:active,
:global .widget-landing .CalendarDay__selected:hover {
  background-color: var(--accent-color);
  border: 4px solid var(--white);
}
:global .dineout-widget .CalendarDay__default:hover,
:global .widget-landing .CalendarDay__default:hover {
  background-color: var(--accent-color);
  color: var(--white);
}
:global .dineout-widget .CalendarDay__selected_span,
:global .dineout-widget .CalendarDay__hovered_span,
:global .widget-landing .CalendarDay__selected_span,
:global .widget-landing .CalendarDay__hovered_span {
  background-color: var(--accent-color);
  color: var(--white);
}
:global .dineout-widget .CalendarDay__blocked_calendar,
:global .dineout-widget .CalendarDay__blocked_calendar:active,
:global .dineout-widget .CalendarDay__blocked_calendar:hover,
:global .dineout-widget .CalendarDay__blocked_out_of_range,
:global .dineout-widget .CalendarDay__blocked_out_of_range:hover,
:global .widget-landing .CalendarDay__blocked_calendar,
:global .widget-landing .CalendarDay__blocked_calendar:active,
:global .widget-landing .CalendarDay__blocked_calendar:hover,
:global .widget-landing .CalendarDay__blocked_out_of_range,
:global .widget-landing .CalendarDay__blocked_out_of_range:hover {
  background-color: var(--gray);
  color: var(--dark-gray);
}
:global .dineout-widget .CalendarMonth_caption,
:global .widget-landing .CalendarMonth_caption {
  padding-top: 0;
  padding-bottom: 43px;
  color: var(--primary-color);
}
:global .dineout-widget .CalendarMonth_caption strong,
:global .widget-landing .CalendarMonth_caption strong {
  font-weight: 500;
}
:global .dineout-widget .DayPicker_weekHeader_li,
:global .widget-landing .DayPicker_weekHeader_li {
  margin: 0 0.5px;
}
:global .dineout-widget .DayPicker_weekHeader_li small,
:global .widget-landing .DayPicker_weekHeader_li small {
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-color);
}
:global .dineout-widget .DayPicker_weekHeader,
:global .widget-landing .DayPicker_weekHeader {
  top: 36px;
  margin-left: -10px;
}
:global .dineout-widget .CalendarMonthGrid__horizontal,
:global .widget-landing .CalendarMonthGrid__horizontal {
  left: 0;
}
:global .dineout-widget .CalendarDay,
:global .widget-landing .CalendarDay {
  font-size: 1rem;
  font-weight: 500;
}
:global .dineout-widget .DayPicker__horizontal,
:global .widget-landing .DayPicker__horizontal {
  margin: 0 auto;
}
:global .dineout-widget .CalendarMonth_caption > div > div,
:global .widget-landing .CalendarMonth_caption > div > div {
  margin: 0 5px;
}
:global .dineout-widget .CalendarMonth_caption select,
:global .widget-landing .CalendarMonth_caption select {
  height: 24px;
  border-bottom: 0;
  padding-right: 20px;
}
:global .dineout-widget .section,
:global .widget-landing .section {
  margin-bottom: 40px;
}
:global .dineout-widget .section-title,
:global .widget-landing .section-title {
  margin: 0 0 22px;
  font-size: 1.5rem;
  font-weight: 400;
}
:global .dineout-widget .section-sub-title,
:global .widget-landing .section-sub-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 400;
}
:global .dineout-widget p.error,
:global .dineout-widget p.success,
:global .widget-landing p.error,
:global .widget-landing p.success {
  margin: 0;
  font-size: 0.75rem;
}
:global .dineout-widget .error,
:global .widget-landing .error {
  color: var(--error-color);
}
:global .dineout-widget .success,
:global .widget-landing .success {
  color: var(--success-color);
}
:global .dineout-widget .star-ratings,
:global .widget-landing .star-ratings {
  display: inline-block;
  margin-right: 10px;
}
:global .dineout-widget .CircularProgressbar .CircularProgressbar-path,
:global .widget-landing .CircularProgressbar .CircularProgressbar-path {
  stroke: var(--awards-color);
  transition: stroke-dashoffset 1s ease 0s;
}
:global .dineout-widget .CircularProgressbar .CircularProgressbar-text,
:global .widget-landing .CircularProgressbar .CircularProgressbar-text {
  fill: var(--awards-color);
  font-size: 1.625rem;
  dominant-baseline: central;
}
:global .dineout-widget .lg-two-cols .col,
:global .widget-landing .lg-two-cols .col {
  margin-bottom: 40px;
}
:global .dineout-widget .slick-prev,
:global .dineout-widget .slick-next,
:global .widget-landing .slick-prev,
:global .widget-landing .slick-next {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 0;
}
:global .dineout-widget .gray-block,
:global .widget-landing .gray-block {
  flex-shrink: 0;
  width: 190px;
  margin-top: 30px;
  padding: 16px;
  border-radius: 16px;
  background-color: var(--gray);
}
:global .dineout-widget .gray-block p,
:global .widget-landing .gray-block p {
  margin: 0;
}
:global .dineout-widget .gray-block .right,
:global .widget-landing .gray-block .right {
  display: block;
  text-align: right;
}
:global .dineout-widget .gray-block.full-width,
:global .widget-landing .gray-block.full-width {
  width: 100%;
  margin: 0;
}
@media only screen and (min-width: 480px) {
  :global .dineout-widget .gray-block,
:global .widget-landing .gray-block {
    margin-top: 0;
    margin-left: 30px;
  }
}
@media only screen and (min-width: 1025px) {
  :global .dineout-widget .lg-two-cols,
:global .dineout-widget .lg-three-cols,
:global .widget-landing .lg-two-cols,
:global .widget-landing .lg-three-cols {
    display: flex;
    justify-content: space-between;
  }
  :global .dineout-widget .lg-two-cols,
:global .widget-landing .lg-two-cols {
    flex-wrap: wrap;
  }
  :global .dineout-widget .lg-two-cols .col,
:global .widget-landing .lg-two-cols .col {
    width: 48.3%;
  }
}
:global .custom-phone-input .react-tel-input .form-control {
  width: 100%;
  height: 46px;
  border: 0;
  border-radius: 0;
  padding-left: 56px;
  border-bottom: 1px solid var(--primary-color);
}
:global .custom-phone-input .react-tel-input .flag-dropdown {
  background-color: var(--white);
}
:global .custom-phone-input .react-tel-input .flag-dropdown,
:global .custom-phone-input .react-tel-input .country-list .search {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--primary-color);
}
:global .custom-phone-input .react-tel-input .selected-flag {
  width: 44px;
}
:global .custom-phone-input .react-tel-input .selected-flag .arrow {
  left: 24px;
}
:global .custom-phone-input .react-tel-input .country-list .search-box {
  width: calc(100% - 32px);
  border: none;
}
:global .form-group.error .custom-phone-input .react-tel-input .form-control,
:global .form-group.error .custom-phone-input .react-tel-input .flag-dropdown {
  border-color: var(--error-color);
}