.checkbox {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  font-size: 0.9375rem;
  cursor: pointer;
  padding: 0;
}
.checkbox input {
  display: none;
}
.checkbox input:checked + .box::after {
  content: "";
  position: absolute;
  top: -3px;
  right: 0;
  width: 8px;
  height: 16px;
  border-bottom: 2px solid var(--accent-color);
  border-right: 2px solid var(--accent-color);
  transform: rotate(45deg);
}
.checkbox a {
  text-decoration: underline;
  color: var(--accent-color);
}
.checkbox .text {
  display: block;
}
.checkbox .box {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  margin-right: 12px;
  background-color: var(--gray);
}