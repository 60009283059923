.search-form {
  background-color: var(--white);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
}

.btn {
  margin-top: 0;
}

.search-inputs {
  background-color: var(--white);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
}
.search-inputs svg {
  position: absolute;
  top: 50%;
  left: 0;
  width: auto;
  height: 35px;
  transform: translateY(-50%);
  fill: var(--white);
  background-color: var(--accent-color);
  border-radius: 4px;
}
.search-inputs input {
  height: 50px;
  padding-left: 54px;
  border-bottom: 0;
}
.search-inputs .btn {
  width: 100%;
}

.wrapper-with-padding {
  padding: 20px;
}

.mobile-search-info {
  display: flex;
  align-items: center;
  padding: 10px;
  font-weight: 700;
  outline: none;
}
.mobile-search-info .icon {
  width: 40px;
  flex-shrink: 0;
  margin-right: 5px;
  margin-left: -10px;
}

.guests-wrapper {
  position: relative;
  margin-bottom: 30px;
  padding-left: 54px;
}

@media only screen and (min-width: 1025px) {
  .search-form {
    width: calc(100% - 320px);
  }

  .search-inputs {
    box-shadow: none;
  }
  .search-inputs input {
    padding-left: 45px;
  }
  .search-inputs .btn {
    width: auto;
  }

  .wrapper-with-padding {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }

  .guests-wrapper {
    flex-shrink: 0;
    width: 185px;
    margin-bottom: 0;
    margin-left: 30px;
    padding-right: 30px;
    padding-left: 45px;
  }
}
@media only screen and (min-width: 1180px) {
  .search-inputs {
    display: flex;
  }
}