.no-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  font-size: 1.125rem;
  font-weight: bold;
  background-color: var(--gray);
  border-radius: 16px;
}
.no-menu svg {
  margin-right: 15px;
}

.menu-gallery-wrapper {
  max-width: 580px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}

.menu-gallery-item {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  display: block;
  position: relative;
  margin: 0 auto;
}
.menu-gallery-item img {
  max-width: 100%;
}

.menu-gallery-controls {
  text-align: center;
  color: var(--lighter-text-color);
}
.menu-gallery-controls strong {
  color: var(--primary-color);
}
.menu-gallery-controls button {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  border: 2px solid var(--accent-color);
  width: 24px;
  height: 24px;
  line-height: 1;
  margin-top: -12px;
}
.menu-gallery-controls .btn-prev {
  transform: rotate(90deg);
  left: 0;
}
.menu-gallery-controls .btn-next {
  transform: rotate(-90deg);
  right: 0;
}