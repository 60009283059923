.review-item {
  position: relative;
  margin-bottom: 25px;
  padding: 15px 15px 30px;
  background-color: var(--gray);
}
.review-item .tip {
  font-weight: 500;
}

.review-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  align-items: center;
}
.user-info p {
  margin: 0;
  font-weight: 500;
}
.user-info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-info .avatar {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: var(--header-border);
  overflow: hidden;
}

.review-text {
  line-height: 1.36;
}
.review-text p {
  margin-top: 0;
  margin-bottom: 8px;
}
.review-text p:last-child {
  margin-bottom: 0;
}

.review-source {
  display: flex;
  justify-content: flex-end;
}
.review-source svg {
  max-width: 100px;
  height: auto;
}