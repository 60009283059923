.restaurant-header {
  position: static;
  height: auto;
}

.restaurant-info-wrapper {
  margin-bottom: 20px;
  padding-top: 20px;
  background-color: var(--gray);
}

.restaurant-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.restaurant-name {
  float: left;
  font-size: 1.5rem;
  font-weight: normal;
}

.working-hours {
  display: block;
}

.loader-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.loader-wrapper img {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 1025px) {
  .restaurant-info {
    flex-wrap: nowrap;
  }
}