.custom-alert {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 5px;
  color: var(--white);
  background-color: var(--accent-color);
  pointer-events: all;
}
.custom-alert button {
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  margin-left: 5px;
  cursor: pointer;
}
.custom-alert .icon {
  width: 40px;
  height: 40px;
  fill: var(--white);
}
.custom-alert.success {
  background-color: var(--success-color);
}
.custom-alert.error {
  background-color: var(--error-color);
}